<template>
  <div>
    <router-link class="come-back" to="/admin/hotline_info">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование блока №${blockHotline.order}` : 'Создание блока' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createBlock" ref="createBlock">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Заголовок</p>
          <input placeholder="Введите заголовок" v-model="blockHotline.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание</p>
          <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="blockHotline.text"></ckeditor>
        </div>

        <div class="content-sb">
          <p>Позиция</p>
          <input placeholder="Введите номер" v-model="blockHotline.order" type="number" name="order" class="input blue">
        </div>

        <div class="content-sb">
          <p>Тег</p>
          <input placeholder="Введите тег" v-model="blockHotline.tag" type="text" name="tag" class="input blue">
        </div>

        <div class="content-sb">
          <p>Телефон</p>
          <input placeholder="Введите телефон" v-model="blockHotline.phone" type="text" name="phone" class="input blue">
        </div>

        <div class="content-sb">
          <p>Превью<br/><span @click="deletePreview()" v-if="previewBlob || blockHotline.image">Удалить</span></p>
          <custom-drop-zone :name="'image'"
                            @setFile="setFile($event)"
                            ref="previewPhoto"
                            :uploaded-blob="previewBlob"/>
        </div>
        <div class="content-sb">
          <p>Ссылка</p>
          <input placeholder="Введите ссылку" v-model="blockHotline.link" type="text" name="link" class="input blue">
        </div>

        <div class="content-sb" v-if="linkDoc">
          <p>Старый файл</p>
          <a :href="linkDoc">Старый файл</a>
        </div>

        <div class="content-sb">
          <p>Файл</p>
          <input type="file" name="document" class="input blue" @input="secondFile">
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/hotline_info" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomDropZone from '@/components/Admin/CustomDropZone'

export default {
  name: 'CreateHotlineInfo',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      linkDoc: null,
      blockHotline: {
        title: null,
        text: null,
        tag: null,
        image: null,
        link: null,
        document: null,
        order: null,
        phone: null
      }
    }
  },

  watch: {
    '$store.getters.getBlockHotLineDetails' () {
      const dataFromServer = this.$store.getters.getBlockHotLineDetails

      this.blockHotline = {
        title: dataFromServer.title,
        text: dataFromServer.text,
        tag: dataFromServer.tag,
        link: dataFromServer.link,
        phone: dataFromServer.phone,
        order: dataFromServer.order
      }
      this.linkDoc = dataFromServer.document

      if (dataFromServer.image) {
        // const previewPhoto = dataFromServer.image.split('/')
        // this.blockHotline.image = previewPhoto[previewPhoto.length - 1]
        this.previewBlob = dataFromServer.image
      }

      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('setBlockHotLineDetails', this.$route.params.id)
    }
  },

  methods: {
    createBlock () {
      const formData = new FormData()

      for (const key in this.blockHotline) {
        if (this.blockHotline[key] !== null) {
          if (key === 'text' && this.blockHotline[key] === '') {
          } else formData.append(key, this.blockHotline[key])
        }
      }

      //   console.debug(...formData)
      if (!this.isEdit) {
        this.$store.dispatch('createBlockHotLine', formData)
      } else {
        this.$store.dispatch('editBlockHotLine', {
          data: formData,
          id: this.$route.params.id
        })
      }
    },

    deletePreview () {
      this.blockHotline.image = null
      this.previewBlob = null
      if (this.$refs.previewPhoto) {
        this.$refs.previewPhoto.removeFile()
      }
    //   this.$forceUpdate()
    },

    setFile (file) {
      this.blockHotline.image = file
    },
    secondFile (event) {
      this.blockHotline.document = event.target.files[0]
    }
  },

  components: {
    CustomDropZone,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 5px;
          background-color: #EEF4FA;
        }
        &::-webkit-scrollbar-track {
          /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
        }
        &::-webkit-scrollbar-thumb {
          background-color: #246CB7;
          border-radius: 2.5px;
        }
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
